import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useEffect, useState, ReactNode } from "react";

const RECAPTCHA_EVENT_LIST: string[] = [
  "mousemove",
  "mousedown",
  "keydown",
  "touchstart",
];

interface GoogleRecaptchaWrapperProps {
  children: ReactNode;
}

export const GoogleRecaptchaWrapper = ({ children }: GoogleRecaptchaWrapperProps) => {
  const [recaptchaProps] = useState({ defer: true });
  const [recaptchaNeeded, setRecaptchaNeeded] = useState(false);

  useEffect(() => {
    RECAPTCHA_EVENT_LIST.forEach((ev) =>
      document.addEventListener(ev, initRecaptcha)
    );

    return () => {
      RECAPTCHA_EVENT_LIST.forEach((ev) =>
        document.removeEventListener(ev, initRecaptcha)
      );
    };
  }, []);

  const initRecaptcha = () => {
    RECAPTCHA_EVENT_LIST.forEach((ev) =>
      document.removeEventListener(ev, initRecaptcha)
    );
    setRecaptchaNeeded(true);
  };

  const wrapperProps = {
    reCaptchaKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || "",
    scriptProps: recaptchaProps,
  };

  if (recaptchaNeeded) {
    return (
      <GoogleReCaptchaProvider {...wrapperProps}>
        {children}
      </GoogleReCaptchaProvider>
    );
  }

  return <>{children}</>;
};
